import React from 'react'
import icon from "../../assets/images/logo.jpg";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='bg-[#1a1a1a] pt-5 md:pt-12'>
            <div className='container mx-auto py-5 px-5'>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-6 px-6 text-center'>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Chennai</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Kochi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Chennai to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Coimbatore</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Kochi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Coimbatore to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Bangalore</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Kochi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Bangalore to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Madurai</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Madurai to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Trichy</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Trichy to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Salem</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Tirunelveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Salem to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Tirunelveli</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Tirunelveli to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Kanyakumari</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Kanyakumari to Rameshwaram taxi</p>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-[#e30d16] text-[23px] sm:text-[25px]  md:text-[26px] font-semibold'>Rameshwaram</h3>
                        <div className='text-white mt-3'>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Chennai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Bangalore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Thoothukudi taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Madurai taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Salem taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Trichy taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Coimbatore taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Pondicherry taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Neyveli taxi</p>
                            <p className='text-[14px] sm:text-[15px] md:text-[16px] font-medium'>Rameshwaram to Kanyakumari taxi</p>
                        </div>
                    </div>
                </div>
                {/* <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mt-9 items-start gap-5 sm:gap-10 md:gap-16 lg:gap-20'>
                    <div>
                        <img src={icon} alt="foot_icon" className='w-[80%] mx-auto' />
                        <p className='text-white pt-5 font-[16px]'>We are providing Oneway Taxi, Outstation Taxi, and Airport Taxi. Hire Oneway Taxi with cheapest price right at your place. Always Available 24/7.</p>
                    </div>
                    <div className='text-[#fff]'>
                        <h5 className='text-[21px] sm:text-[23px] md:text-[25px]'>Outstation Service</h5>
                        <ul className='pt-2 md:pt-5 text-[#fff] text-[14px] footer-dot-par'>
                            <li>One Way</li>
                            <li className='mt-1'>Round Trip</li>
                            <li className='mt-1'>Round Trip</li>
                            <li className='mt-1'>Drop Taxi</li>
                            <li className='mt-1'>Airport Taxi</li>
                            <li className='mt-1'>Rental Taxi</li>
                        </ul>
                    </div>
                    <div className='text-[#fff]'>
                        <h5 className='text-[21px] sm:text-[23px] md:text-[25px]'>Quick Links</h5>
                        <ul className='pt-2 md:pt-5 text-[#fff] text-[14px] footer-dot-par'>
                            <li className='mt-1'><span className=' cursor-pointer'><a href="/">Home</a></span></li>
                            <li className='mt-1'><a href="#pop_routes">Popular Routes</a></li>
                            <li className='mt-1'><a href="#cars">Cars / Tarrif</a></li>
                            <li className='mt-1'><a href="#footer_contact">Contact</a></li>
                            <li className='mt-1'><a href="#book_taxi_form">Book Now</a></li>
                            <li className='mt-1'> <Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                            <li className='mt-1'><Link to="/privacy-policy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                    <div className='text-[#fff]' id='footer_contact'>
                        <h5 className='text-[21px] sm:text-[23px] md:text-[25px]'>Official Info</h5>
                        <div className='pt-2 sm:pt-3 md:pt-4  sm:pl-0 md:pl-5'>
                            <span className='text-[18px] font-medium'>Location:</span>
                            <p className='text-[14px]'>Coimbatore, Tamil Nadu, India.</p>
                        </div>
                        <div className='pt-2 sm:pt-3 md:pt-4 sm:pl-0 md:pl-5'>
                            <span className='text-[18px] font-medium'>Email:</span>
                            <p className='text-[14px]'><a href="mailto:lovelycabsonewaytaxiservice@gmail.com">lovelycabsonewaytaxiservice@gmail.com</a></p>
                        </div>
                        <div className='pt-2 sm:pt-3 md:pt-4 sm:pl-0 md:pl-5'>
                            <span className='text-[18px] font-medium'>Phone:</span>
                            <p className='text-[14px]'><a href="tel:+918148148299">+91 8148148299</a></p>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='text-white text-center  border-t-[1px] py-4 border-[#ffffff26] text-[14px] md:text-[15px] '>
                © Fast Track DropMe Taxi  All Rights Received.
            </div>
        </div>
    )
}

export default Footer