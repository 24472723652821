import React, { useEffect, useState } from 'react'
import { MdLocationPin } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import logo from '../assets/images/logo.jpg';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiFillCloseCircle } from 'react-icons/ai';
import NavList from './NavList';
import { Link } from 'react-router-dom';
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [openNav, setOpenNav] = useState(false)
    function openNavBat() {
        setOpenNav(true);
    }
    function closeNavBar() {
        setOpenNav(false);
    }

    useEffect(() => {
        // Define a function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Conditionally apply the class based on the state
    const headerClass = isScrolled ? 'header_parent header_scrolled' : 'header_parent';

    return (
        <div >
            <div className={headerClass}>
                <div className='bg-[#ffc503]'>
                    <div className='container mx-auto relative px-0 sm:px-4 md:px-8 lg:px-16'>
                        <div className='flex justify-center md:flex-row  md:flex-nowrap py-3 sm:py-5 md:py-8 items-center gap-y-3 md:gap-x-10'>
                            {/* <div className='flex items-center gap-x-4'>
                                <span className='text-[#241d92]'><MdLocationPin size={20} /></span>
                                <span className='font-semibold '>Coimbatore , Tamilnadu</span>
                            </div> */}
                            <div>
                                <a href="tel:+918148148299" className=' relative z-10 flex gap-x-4 items-center'>
                                    <span className='text-[#241d92]'><BsFillTelephoneFill /></span>
                                    <span className='font-semibold'>+918148148299</span>
                                </a>
                            </div>
                         </div>
                    </div>
                </div>
                <div className=' bg-white'>
                    <div className='container mx-auto relative px-0 sm:px-0 md:px-4  lg:px-16'>
                        <div className='flex gap-x-5 justify-center items-center'>
                          <Link to="/">
                                <img src={logo} alt="logo" className='w-[150px] sm:w-[200px]  lg:w-[250px]' />
                                </Link>
                            {/* <div className='hidden  md:inline-block'>
                                <NavList ul_class="flex  gap-x-3 md:gap-x-5 text-lg sm:text-base  md:text-lg lg:text-xl text-[#241D92] items-center nav_link_parent" li_class="" />
                            </div>
                            <div className='block md:hidden cursor-pointer pr-4'>
                                <span onClick={openNavBat} ><RxHamburgerMenu size={23} /></span>
                            </div> */}
                        </div>
                        {/* <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
                            <div className='px-4 py-3 bg-[#ffc9cb]'>
                                <img src={logo} alt="logo" className='w-[250px] mx-auto px-5' />
                            </div>
                            <div className='py-6 px-4'>
                                <NavList ul_class="font-semibold text-[14px] flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
                                <span onClick={closeNavBar} className='absolute top-4 -right-3 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header