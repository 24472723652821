import React from "react";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import Look from "../components/Look";

const Home = () => {
  return (
    <>
      <BookingForm />
      {/* <About /> */}
      {/* <PopularRoutes /> */}
      <Cars />
      <Look />
    </>
  );
};

export default Home;
